import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import GoogleMap from "@sections/Map";
import { JsonLd } from "./../common/JsonLd";

import { Section, Container } from "@components/global";

import FaqItem from "@common/FaqItem";
import { Link } from "gatsby";

import { Phone } from "styled-icons/icomoon";
import { MapPin } from "styled-icons/boxicons-solid";
import { PinDistance } from "styled-icons/remix-fill";
import { LocalGasStation } from "styled-icons/material";
import { PiggyBank } from "styled-icons/fa-solid";
import { Payment } from "styled-icons/material";
import { Percent } from "styled-icons/remix-fill";
import { CalendarPlus } from "styled-icons/fa-solid";

import { SmokingBan } from "styled-icons/fa-solid";
import { Road } from "styled-icons/fa-solid";
import { Roadster } from "styled-icons/remix-fill";
import { BadgeCheck } from "styled-icons/boxicons-solid";
import { HandPointer } from "styled-icons/fa-solid";
import { Smile } from "styled-icons/boxicons-solid";

const FAQS = [
  {
    title:
      "Co muszę zrobić, żeby wynająć auto w wypożyczalni samochodów w Gdańsku?",
    content: () => (
      <>
        <p>
          Aby wynająć auto w naszej wypożyczalni samochodów na terenie Gdańska i
          Trójmiasta wystarczy wybrać jedno z aut w zakładce{" "}
          <Link to="/samochody">samochody</Link>, a następnie określić czas
          najmu oraz miejsce odbioru i zwrotu pojazdu . Strona sama zapyta nas o
          potrzebne dodatki. Teraz trzeba wypełnić formularz kontaktowy i czekać
          na odpowiedź ze strony obsługi klienta, która potwierdzi rezerwację.
        </p>
      </>
    ),
  },
  {
    title: "Jakie dokumenty są potrzebne, aby wynająć samochód?",
    content: () => (
      <>
        <p>
          Na etapie wynajmu, czyli odbioru samochodu wymagamy od Państwa
          następujących dokumentów:
        </p>
        <ul>
          <li>
            Aktualne prawo jazdy, każdego z kierowców, upoważnionych do
            kierowania pojazdem
          </li>
          <li>Paszport lub dowód osobisty ważny przez kolejne 6 miesięcy</li>
        </ul>

        <p style={{ textAlign: "center" }}>
          <strong>UWAGA ! NIE AKCEPTUJEMY ZDJĘĆ DOKUMENTÓW.</strong>
        </p>
      </>
    ),
  },
  {
    title: "W jakim muszę być wieku i ile lat posiadać prawo jazdy?",
    content: () => (
      <>
        <p>
          Warunkiem wynajmu samochodu jest ukończone 18 lat, oraz posiadanie
          prawa jazdy kategorii B.
        </p>
      </>
    ),
  },
  {
    title: "Czym różni się jazda samochodami z instalacją LPG?",
    content: () => (
      <>
        <p>
          W swojej ofercie wypożyczalni samochodów mamy dostępne auta benzynowe
          z instalacją LPG, dzięki której koszty eksploatacji wynajętego pojazdu
          znacznie się zmniejszają. Najemcę nie interesuje nic, poza tym, żeby
          zatankować samochód benzyną i gazem. Auto po przejechaniu około 1
          kilometra, samoczynnie przełączy się na gaz po każdym uruchomieniu
          silnika , a Najemca odczuje różnicę dopiero gdy pojedzie zatankować
          pojazd, w postaci pieniędzy, które zaoszczędzi.
        </p>
      </>
    ),
  },
  {
    title: "Jak tankować LPG?",
    content: () => (
      <>
        <p>
          Samochody z instalacją LPG posiadają dodatkowy korek wlewu gazu, za
          pomocą którego tankuje się auto gazem propan-butan. Zależnie od
          samochodu jaki wybierze Najemca, korek wlewu ukryty będzie pod klapką
          wlewu benzyny, bądź w tylnym zderzaku auta. Należy podjechać stroną, z
          której jest umieszczony korek wlewu pod dystrybutor, a następnie
          podpiąć pistolet do samochodu. Można również poprosić obsługę na
          stacji paliw, która ma obowiązek zatankować pojazd.
        </p>
      </>
    ),
  },
  {
    title: "Jak mogę zapłacić za najem samochodu?",
    content: () => (
      <>
        <p>
          Płatność dokonywana jest po potwierdzeniu rezerwacji auta, za pomocą
          bankowości internetowej, bądź na miejscu przy odbiorze samochodu.
          Obsługujemy płatności kartą płatniczą oraz gotówką w zależności od
          upodobań klienta.
        </p>
      </>
    ),
  },
  {
    title: "Ile kosztuje wynajem samochodu w RENT ME CAR?",
    content: () => (
      <>
        <p>
          Cena najmu uzależniona jest od wielu czynników, zachęcamy do
          wypełnienia całego formularza rezerwacji, przez co na końcu dowiedzą
          się Państwo jaki jest całkowity koszt wynajmu samochodu.
        </p>
      </>
    ),
  },
  {
    title: "Czy samochody w firmie RENT ME CAR posiadają ubezpieczenie?",
    content: () => (
      <>
        <p>
          Zdecydowanie tak, wszystkie samochodu w naszej wypożyczalni posiadają
          wymagane ubezpieczenia.
        </p>
      </>
    ),
  },
  {
    title: "Czy jest limit kilometrów?",
    content: () => (
      <>
        <p>
          Przy krótkoterminowym wynajmie samochodu obowiązuje limit kilometrów
          na poziome 250km/doba. Limit kilometrów na każdą dobę sumuje się, a w
          przypadku przekroczenia limitu naliczania jest opłata w wysokości 30
          zł, za każe rozpoczęte 100km ponad określoną sumę kilometrów.
        </p>
      </>
    ),
  },
  {
    title: "Czy kaucja zabezpieczająca jest obowiązkowa?",
    content: () => (
      <>
        <p>
          Wynajem bez uiszczania kaucji jest możliwy powyżej 3 dni oraz w
          przypadku wykupienia dodatkowego ubezpieczenia, które znosi udział
          własny Najemcy w szkodzie. Zarówno kwota ubezpieczenia jak i wysokość
          kaucji uzależniona jest od klasy samochodu i podana w opisie pojazdu.
          Wyjątkiem jest najem pojazdów segmentu PREMIUM, gdzie wysokość kaucji
          wynosi 3000zł, a jej uiszczenie jest obowiązkowe.
        </p>
      </>
    ),
  },
  {
    title: "Jak rozwiązana jest sprawa tankowania auta?",
    content: () => (
      <>
        <p>Klient odbiera zatankowany samochód do pełna i taki też oddaje.</p>
      </>
    ),
  },
  {
    title: "Czy podstawiacie i odbieracie samochód na lotnisku?",
    content: () => (
      <>
        <p>
          Tak, w pakiecie naszych usług, mamy darmowe podstawienie i odbiór
          samochodu na lotnisku.
        </p>
      </>
    ),
  },
  {
    title: "Czy wynajęty pojazd, może prowadzić druga osoba?",
    content: () => (
      <>
        <p>
          Wynajęty samochód, mogą prowadzić tylko osoby, które zostały
          upoważnione przez wypożyczalnię samochodów RENT ME CAR do prowadzenie
          pojazdu, poprzez wpisanie ich danych osobowych w umowę najmu pojazdu.
          Istnieję możliwość wykupienia upoważnienia dodatkowego kierowcy do
          kierowania pojazdem.
        </p>
      </>
    ),
  },
  {
    title: "Gdzie mogę jeździć wynajętym samochodem?",
    content: () => (
      <>
        <p>
          Standardowy najem pojazdu w naszej wypożyczalni obejmuje teren całej
          Polski. Na życzenie klienta istnieje możliwość wykupienia dodatkowego
          wyjazdu poza granicę Polski, do krajów Unii Europejskiej. Fakt ten,
          należy zgłosić przed dokonaniem wynajmu pojazdu.
        </p>
      </>
    ),
  },
  {
    title: "Co gdy spowoduję kolizję/wypadek wynajętym samochodem?",
    content: () => (
      <>
        <p>
          Przede wszystkim w pierwszej kolejności trzeba zadbać o siebie i
          zabezpieczyć pojazd. Następnie niezwłocznie i jak najszybciej
          powiadomić wynajmującego o tym fakcie, a na miejsce zdarzenia wezwać
          policję. Aby uniknąć wysokich i kosztownych opłat związanych z naprawą
          wynajętego samochodu, polecamy wykupić wcześniej dodatkowe
          ubezpieczenie, które znosi udział własny w szkodzie na wynajętym
          pojeździe, dzięki czemu Najemca nie zostanie obciążony kosztami
          uszkodzonego pojazdu. Dodatkowo ubezpieczenie znosi konieczność
          uiszczenia kaucji, za wynajęty samochód.
        </p>
      </>
    ),
  },
  {
    title: "Czy mogę przedłużyć wynajem samochodu?",
    content: () => (
      <>
        <p>
          Oczywiście ! Należy powiadomić wypożyczalnię samochodów RENT ME CAR 24
          godziny, przed końcem okresu najmu, wskazanym na umowie, oraz uiścić
          wpłatę z góry, za dodatkowy okres wybraną formą płatności:
        </p>
        <ul>
          <li>osobiście w placówce wynajmującego</li>
          <li>
            wpłatą w banku na rachunek wynajmującego, pieniądze muszą zostać
            zaksięgowane, przed rozpoczęciem się przedłużonej doby najmu !
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Czy wystawiacie fakturę VAT?",
    content: () => (
      <>
        <p>
          Oczywiście, jeśli chcesz otrzymać fakturę VAT, musisz zaznaczyć tę
          opcję w formularzu kontaktowym i podać dane do faktury.
        </p>
      </>
    ),
  },
  {
    title: "Kiedy zostanie zwrócona kaucja?",
    content: () => (
      <>
        <p>
          Kaucja pobrana w gotówce, jest zwracana podczas odbioru samochodu,
          jeśli płatność dokonana została przelewem, bądź kartą, wypożyczalnia
          samochodów RENT ME CAR, przeleje kaucje na wskazany numer konta
          klienta niezwłocznie po odbiorze samochodu.
        </p>
      </>
    ),
  },
  {
    title: "Co jeśli spóźnię się z odbiorem, bądź zwrotem samochodu?",
    content: () => (
      <>
        <p>
          Prosimy tego nie robić ! Jeśli zajdzie wyjątkowa sytuacja, prosimy
          poinformować nas niezwłocznie telefonicznie, na numer infolinii. W
          innym wypadku wypożyczalnia samochodów RENT ME CAR może nałożyć karę
          przewidzianą w OWU.
        </p>
      </>
    ),
  },
  {
    title: "Jak mam zwrócić pojazd?",
    content: () => (
      <>
        <p>
          Przyjedź samochodem na wskazany adres w umowie najmu. Samochód możesz
          zwrócić wcześniej, lecz nie później niż do czasu określonego w umowie
          najmu. Umyj i zatankuj wcześniej auto, a następnie sprawdź z
          pracownikiem wypożyczalni samochodów RENT ME CAR czy nie ma żadnych
          uszkodzeń samochodu, zarówno zewnątrz jak i w wewnątrz. Zwróć kluczyki
          i dokumenty od auta. Wypożyczalnia samochodów RENT ME CAR nie
          odpowiada za rzeczy pozostawione w samochodzie, po jego zwrocie.
        </p>
      </>
    ),
  },
  {
    title: "Co to są dodatki?",
    content: () => (
      <>
        <p>
          W celu świadczenia najlepszych usług, przygotowaliśmy dla naszych
          klientów dodatkowe opcje, które można wybrać do wynajętego samochodu,
          takie jak fotelik dla dziecka, nawigacja GPS, możliwość oddania
          brudnego auta, czy wyjazd samochodem poza granice Polski do krajów UE.
        </p>
      </>
    ),
  },
  {
    title: "Czy samochody mają opony zimowe i letnie?",
    content: () => (
      <>
        <p>
          Wszystkie nasze pojazdy w okresie od 1 listopada do 31 marca jeżdżą na
          oponach zimowych, poza tym okresem, samochody są na oponach letnich.
        </p>
      </>
    ),
  },
  {
    title: "Czy mogę przewozić w samochodzie zwierzęta?",
    content: () => (
      <>
        <p>
          Tak, pod warunkiem, że będą one zamknięte w odpowiednich pojemnikach
          do przewożenia zwierząt oraz auto nie przejdzie zapachem zwierzaka. W
          innym przypadku, na Najemcę zostanie nałożona opłata za sprzątanie
          samochodu, bądź pranie tapicerki.
        </p>
      </>
    ),
  },
  {
    title: "Czy mogę palić w samochodzie?",
    content: () => (
      <>
        <p>
          Ogólne warunki umowy, kategorycznie zabraniają palenia w samochodzie
          papierosów, papierosów elektronicznych i wszelkich podobnych wyrobów
          nikotynowych.. Za złamanie tego zapisu, Najemca zostanie obciążony
          karą. Nasze samochody posiadają czujniki odpowiedzialne za wykrywanie
          dymu z papierosa.
        </p>
      </>
    ),
  },
  {
    title:
      "Co w przypadku gdy samochód się zepsuł, bądź miał udział w szkodzie i nie nadaje się do dalszej jazdy?",
    content: () => (
      <>
        <p>
          Jak najszybciej należy skontaktować się z wypożyczalnią samochodów,
          poinformować o fakcie awarii, bądź kolizji, a my udzielimy potrzebnej
          pomocy w postaci samochodu zastępczego.
        </p>
      </>
    ),
  },
  {
    title: "Zostawiłem ważny przedmiot w samochodzie, co mam zrobić?",
    content: () => (
      <>
        <p>
          Skontaktuj się z wypożyczalnią samochodów RENT ME CAR jak najszybciej
          się da, a my zrobimy wszystko co w naszej mocy, żeby pomóc odnaleźć
          zagubiony przedmiot, lecz nie gwarantujemy odnalezienia przedmiotu i
          nie odpowiadamy, za rzeczy pozostawione w samochodzie.
        </p>
      </>
    ),
  },
];

const FaqSection = styled.div``;
const AsideSection = styled.div``;
const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "aside" "faq";
  grid-gap: 2rem;
  @media (min-width: 750px) {
    grid-template-areas: "faq aside";
    grid-template-columns: 1fr 1fr;
  }
  ${FaqSection} {
    grid-area: faq;
  }
  ${AsideSection} {
    grid-area: aside;
  }
`;
const StyledMap = styled(GoogleMap)`
  iframe {
    height: 200px;
  }

  #map {
    height: 200px;
  }
`;

const BelowMap = styled.div``;

export const Blah = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.regular};
  margin: 0.5rem 0;
  display: block;
`;

export const BigBla = styled.div`
  box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
  border-radius: 4px;
  margin: 2rem 0;
  ul {
    list-style: none;
    li {
      position: relative;
      margin: 0.75rem 0;
    }
    li::before {
      content: "✅";
      position: absolute;
      left: -30px;
    }
  }
`;
export const Fff = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 0.5rem;
  margin: 1.5rem 0;
  align-items: center;
  svg {
    fill: #555;
    color: #555;
    transform: scale(0.8);
    width: 50px;
    height: 50px;
  }
`;

const Faq = () => {
  const rightFaq = [...FAQS];
  const half_length = Math.ceil(FAQS.length / 1.5);
  const leftFaq = rightFaq.splice(0, half_length);

  return (
    <Section id="faq">
      <Container>
        <Wrapper>
          <AsideSection>
            <StyledMap />
            <BelowMap>
              <BigBla>
                <Blah>
                  Kontakt - Rent Me Car Najtańsza wypożyczalnia samochodów
                </Blah>
                <Fff>
                  <MapPin />
                  <div>
                    Darmowe miejsce odbioru - znajduje się pod adresem: Gdańsk
                    Główny ul. Aksamitna 9a
                  </div>
                </Fff>
                <Fff>
                  <Phone />
                  <div>
                    Infolinia czynna 24/7:{" "}
                    <a href="tel:+48883154531">+48 883-154-531</a>
                  </div>
                </Fff>
              </BigBla>
              <BigBla>
                <Blah>Wymagane dokumenty do wynajmu samochodu:</Blah>
                <ul>
                  <li>Prawo jazdy - z prawidłową datą ważności</li>
                  <li>Dowód osobisty - z prawidłową datą ważności.</li>
                </ul>
              </BigBla>
              <BigBla>
                <Fff>
                  <PinDistance />
                  <div>
                    <b>250 km</b> - dobowy limit kilometrów, który sumuje się na
                    cały okres najmu.
                  </div>
                </Fff>
                <Fff>
                  <LocalGasStation />
                  <div>
                    <b>Tankowanie</b> - odbierasz i oddajesz samochód z takim
                    samym poziomem paliwa.
                  </div>
                </Fff>
                <Fff>
                  <PiggyBank />
                  <div>
                    <b>Brak kaucji</b> - jeśli zdecydujesz się na wykupienie
                    dodatkowego ubezpieczenia nie musisz wpłacać kaucji za
                    wynajęte auto.
                  </div>
                </Fff>
                {/* <Fff>
                <Payment />
                <div>
                  <b>Przyjmujemy płatność kartą</b> - za wynajem samochodu w
                  Gdańsku zapłacisz kartą.
                </div>
              </Fff> */}
                <Fff>
                  <Percent />
                  <div>
                    <b>Zniżki dla stałych klientów</b> - jeśli jesteś u nas
                    częściej możesz liczyć na rabaty i zniżki.
                  </div>
                </Fff>
                <Fff>
                  <CalendarPlus />
                  <div>
                    <b>Przedłużenie wynajmu</b> - jeśli potrzebujesz samochód na
                    dłużej niż planowałeś na początku, zadzwoń do nas!
                  </div>
                </Fff>
              </BigBla>
              <BigBla>
                <Blah>Podstawowe zasady:</Blah>
                <Fff>
                  <SmokingBan />
                  <div>
                    <b>
                      Zakaz palenia wyrobów tytoniowych i e-papierosów w
                      wynajętym samochodzie
                    </b>{" "}
                    - za palenie w samochodzie grozi kara w wysokości 500 zł.
                  </div>
                </Fff>
                <Fff>
                  <Road />
                  <div>
                    <b>Jazda piracka</b> - nie określamy limitu prędkości, ale
                    za łamanie przepisów ruchu drogowego odpowiada Wynajmujący.
                  </div>
                </Fff>
                <Fff>
                  <Roadster />
                  <div>
                    <b>Czystość samochodu</b> - dbając o najwyższą jakość
                    świadczonych usług wydajemy czyste samochody i oczekujemy
                    zwrotu pojazdu w takim samym stanie.
                  </div>
                </Fff>
                <Fff>
                  <BadgeCheck />
                  <div>
                    <b>Szanuj wynajęty samochód</b> - jazda do odcinki, palenie
                    gumy, próba driftowania nie jest przez nas akceptowana w
                    żadnej formie. Szanuj wynajęty samochód tak jak swój własny.
                  </div>
                </Fff>
                <Fff>
                  <HandPointer />
                  <div>
                    <b>Oszustwa</b> - każda próba oszustwa, kombinacji,
                    nieuczciwości (używanie pojazdu nie zgodnie z
                    przeznaczeniem) zostanie przez nas bardzo szybko
                    zweryfikowana.
                  </div>
                </Fff>
                <Fff>
                  <Smile />
                  <div>
                    <b>Najważniejsze</b> - przestrzeganie zasad i szacunku
                    skutkuje pełnym zadowoleniem obu stron i przyszłe rabaty.
                  </div>
                </Fff>
              </BigBla>
            </BelowMap>
            {rightFaq.map(({ title, content }) => (
              <FaqItem title={title} key={title}>
                {content()}
              </FaqItem>
            ))}
          </AsideSection>
          <FaqSection>
            <h1 style={{ marginBottom: 40 }}>Najczęściej zadawane pytania</h1>
            <p>
              Poniżej przygotowaliśmy dla Państwa odpowiedzi na najczęściej
              zadawane pytania.
            </p>

            <JsonLd
              data={{
                "@context": "http://schema.org",
                "@type": "FAQPage",
                mainEntity: [
                  ...leftFaq.map(({ title, content }) => ({
                    "@type": "Question",
                    name: title,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: renderToStaticMarkup(content()),
                    },
                  })),
                  ...rightFaq.map(({ title, content }) => ({
                    "@type": "Question",
                    name: title,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: renderToStaticMarkup(content()),
                    },
                  })),
                ],
              }}
            />
            {leftFaq.map(({ title, content }) => (
              <FaqItem title={title} key={title}>
                {content()}
              </FaqItem>
            ))}
          </FaqSection>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Faq;
