import React from "react";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";

import Faq from "@sections/Faq";
import Footer from "@sections/Footer";

const FaqPage = () => (
  <Layout
    seo={{
      url: "https://www.najtansza-wypozyczalnia-samochodow.pl/faq",
    }}
  >
    <TopHeader />
    <Faq />
    <Footer />
  </Layout>
);

export default FaqPage;
